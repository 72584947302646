* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
  width: 100%;
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

.project-desc a {
  text-decoration: none;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}

.toolbar-navigation {
  background-color: #fff;
  color: #000;
}

.nav-link {
  font-family: inherit !important;
  transition: all 0.3s ease-in-out 0s;
}

.landing-box {
  background-image: url("../../public/images/46.png");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
}

.landing-box:before {
  position: absolute;
  content: "";
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(241, 242, 247, 0.87);
}

.project-detail {
  font-size: 14px !important;
}

.main-color-text {
  color: #ed1c24 !important;
}

.main-bg-text-color {
  color: #fff !important;
}

.main-color-bg {
  background: #ed1c24 !important;
}

.item-center {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

body {
  overflow-y: auto;
  height: 100vh;
  background: #f3f3f3 !important;
  font-family: "Kanit", sans-serif !important;
}

.font-family-sidebar span {
  font-family: inherit !important;
}

#root {
  height: 100%;
}

.modal-body-height {
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 15px !important;
  height: calc(100vh - 100px);
}

.modal-container {
  padding: 5px 20px;
}
.modal-container .modal-header {
  padding: 5px 0px;
}
.modal-container .modal-body {
  border-top: 1px solid #c9c9c9;
  border-bottom: 1px solid #c9c9c9;
}
.modal-container .modal-footer {
  margin-top: 10px;
}

.avatar-container {
  padding-left: 8px;
}
.avatar-container .avatar-name {
  font-size: 20px;
}
.avatar-container .avatar-role {
  font-size: 14px;
}

.display-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.loading-image-box {
  max-width: 800px;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.validation-error-msg {
  font-size: 12px;
  color: red;
}

.set-height {
  width: 100%;
  position: relative;
  top: 50px;
}

.footer-comp {
  width: 100%;
  bottom: 0px;
}

.form-container-box {
  background: inherit;
  max-width: 450px;
  margin: auto;
  padding-top: 20px;
  padding-bottom: 20px;
}

.auth-logo-container {
  max-width: 200px;
  margin: auto;
  padding: 10px 0px;
}

.auth-active-tab {
  background-color: #ed1c24;
  color: #fff;
}

.footer-copywrite {
  background-color: #fff;
  box-shadow: 0px 0px 5px #000;
}

.login-tab {
  font-size: 22px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  cursor: pointer;
  padding: 10px 15px;
}

.form-box-row {
  box-shadow: 0px 0px 5px #828282;
  border-radius: 10px;
}

.form-box {
  padding: 10px 20px;
}

.login-tab:hover {
  background-color: #ed1c24 !important;
  color: #fff;
}

.auth-form-head {
  color: #ed1c24;
}

.forget-password {
  color: #ed1c24;
  cursor: pointer;
  font-size: 14px;
}

.form-control:focus,
.form-check-input:focus {
  box-shadow: none !important;
}

.text-red-color {
  color: #ed1c24;
}

.btn-bg-color {
  background-color: #ed1c24 !important;
  color: #fff !important;
}

.navbar-dash {
  box-shadow: 0px 0px 5px #000;
}

.navbar-avatar {
  box-shadow: 0px 0px 5px #000;
}

.main-container-pages {
  position: relative;
  top: 65px;
  overflow-y: scroll;
  overflow-x: auto;
  padding: 5px 12px;
  height: calc(100vh - 110px);
}

.dash-toolbar {
  background-color: #fff !important;
}

.element-center {
  display: flex;
  align-items: center;
}

.dash-footer {
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 11;
}

.sidebar-active {
  background: #ff1f01 !important;
  border-radius: 0px 13px 13px 0px !important;
  color: #fff !important;
}

.sidebar-listitem {
  margin: 5px 0px !important;
}

.sidebar-listitem:hover {
  background: #ff6b6b !important;
  border-radius: 0px 13px 13px 0px !important;
  color: #fff !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
  box-shadow: 0px 0px 3px #000 !important;
}

.drop-icon {
  color: red;
}

.card-box {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  padding: 3px 5px;
}

.img-dash-box {
  max-width: 55px;
  margin: auto;
}

.table-strip-back > tbody > tr:nth-child(odd) {
  color: inherit;
  background-color: #eeeeee;
}

.table-strip-back > tbody > tr:nth-child(even) {
  color: inherit;
}

.page-container {
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 15px;
  margin: 5px 5px 5px 0px;
  overflow: auto;
  max-height: 85vh;
  box-shadow: 0px 0px 2px #000;
}

.page-container:hover {
  box-shadow: 0px 0px 4px #000;
}

.tables {
  min-width: 1440px;
}
.tables thead {
  background-color: #ed1c24;
  color: #fff;
}

tr {
  vertical-align: middle;
}

.action-button-container {
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  cursor: pointer;
  background-color: #e4e4e4;
  padding: 5px;
}

.action-button-container:hover {
  box-shadow: 0px 0px 2px #000;
}

.cursor-pointer {
  cursor: pointer;
}

.task-info-count {
  font-size: 14px;
}
.task-info-count span {
  color: #c9c9c9;
}

.auth-img-side {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eaeeef;
  box-shadow: 0px 0px 8px #000;
}

.auth-layout-back {
  height: 100vh;
}

.error-layout {
  height: 100%;
  background-color: #fff;
}

.error-img-container {
  max-width: 500px;
  margin: auto;
}

.error-content-container h6 {
  font-size: 26px;
}
.error-content-container h6 span {
  font-size: 40px;
}

.task-info-count:hover {
  cursor: pointer;
}
.task-info-count:hover span {
  color: #000;
}

.side-context-section {
  width: 100%;
}

.img-container {
  max-width: 500px;
  margin: auto;
}

.hero-head-text {
  font-size: 55px;
}

.her-sec-button button {
  padding: 10px 36px 8px;
}
.her-sec-button button h4 {
  font-weight: 400;
}

.container .hero-section {
  margin: 0px 0px 16px;
}
.container .joinus-section {
  margin: 28px 0px 16px;
}

.logo-container-home {
  max-width: 325px;
  margin: auto;
}

.hero-main-sec {
  position: relative;
}

.join-us-section {
  position: relative;
}

.home-obj-3 {
  position: absolute;
  top: 10px;
  right: 0px;
  max-width: 40px;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: bounce-2;
  animation-timing-function: ease-in-out;
}

.home-obj-1 {
  position: absolute;
  top: 10px;
  max-width: 40px;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: bounce-2;
  animation-timing-function: ease-in-out;
}

@keyframes bounce-2 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}
.home-obj-2 {
  position: absolute;
  bottom: 10px;
  right: 50%;
  max-width: 40px;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: bounce-1;
  animation-timing-function: ease-in-out;
}

@keyframes bounce-1 {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}
.task-info-container .task-card-body .task-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.hoverClass:hover {
  box-shadow: 0px 0px 4px #000;
}

.org-card-position {
  max-width: 750px;
  margin: auto !important;
}

.tab-head {
  font-size: 17px;
  padding: 2px 20px;
  border-radius: 50px;
  cursor: pointer;
}

.tab-head:hover {
  background-color: rgba(255, 0, 10, 0.2);
}

.tab-head-active {
  background-color: rgba(255, 0, 10, 0.5019607843);
}

table tr:hover {
  background: #ededed !important;
  box-shadow: 0px 0px 4px #000;
}

.task-table {
  min-width: 900px;
}
.task-table thead {
  background-color: rgba(255, 139, 143, 0.9411764706);
}
.task-table thead th {
  font-weight: 500;
  font-size: 15px;
  padding: 5px 8px;
}

.accordion-body {
  padding: 10px !important;
}

.task-info-Box-Pending {
  cursor: pointer;
  background-color: #bae6ff !important;
}

.task-info-Box-InProcess {
  cursor: pointer;
  background-color: #ffddc6 !important;
}

.task-info-Box-Testing {
  cursor: pointer;
  background-color: rgba(129, 91, 221, 0.4117647059) !important;
}

.task-info-Box-COMPLETED {
  cursor: pointer;
  background-color: #e5f5e9 !important;
}

.task-info-Box-Backlogs {
  cursor: pointer;
  background-color: #ffc5e3 !important;
}

.task-page-head {
  flex-wrap: nowrap !important;
  overflow-x: auto !important;
  height: calc(100vh - 180px) !important;
}

.view-toggle {
  border: 2px solid #000;
  display: flex;
  border-radius: 5px;
}
.view-toggle div svg {
  font-size: 32px;
}
.view-toggle div:hover {
  background-color: #ed1c24;
}
.view-toggle .toggle-active {
  background-color: #ed1c24;
  color: #fff;
}

.task-card {
  background: #fff;
  padding: 8px 8px;
  border-radius: 10px;
}
.task-card .task-head {
  display: flex;
  align-items: center;
}
.task-card .task-head .task-navigate-arrow {
  display: none;
}
.task-card .task-body .task-card-desc {
  display: -webkit-box;
  max-width: 450px;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: 50px;
}
.task-card .task-body .task-button {
  display: none;
}
.task-card .task-body .task-other {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.taskcard-Pending {
  border-left: 5px solid #bae6ff;
}

.taskcard-InProcess {
  border-left: 5px solid #ffddc6;
}

.taskcard-Testing {
  border-left: 5px solid rgba(129, 91, 221, 0.4117647059);
}

.taskcard-COMPLETED {
  border-left: 5px solid #84ff84;
}

.taskcard-Backlogs {
  border-left: 5px solid #ffc5e3;
}

.task-kanban-title {
  font-size: 20px;
  padding: 4px 0px 5px;
  margin: 5px 0px 10px;
  font-weight: 600;
}

.task-card:hover {
  box-shadow: 0px 0px 3px #000;
  cursor: pointer;
}
.task-card:hover .task-head .task-navigate-arrow {
  display: block;
  color: #ed1c24;
}
.task-card:hover .task-body .task-button {
  display: block;
}
.task-card:hover .task-body .task-other {
  display: none;
}

.chat-comp {
  height: calc(100vh - 7px);
}

.priority-circle {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-right: 5px;
}

.priority-color-HIGH {
  background-color: #ed1c24;
}

.priority-color-MEDIUM {
  background-color: #ffff01;
}

.priority-color-LOW {
  background-color: #2afc2a;
}

.discussion-container {
  height: calc(100vh - 200px);
}

.project-color-COMPLETED {
  background-color: #15fb4a;
}

.project-color-HOLD {
  background-color: #ff01fb;
}

.project-color-ACTIVE {
  background-color: #3dcbff;
}

.profile-image img {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
}
.profile-image img:hover {
  background-color: #000;
}

.profile-button-container {
  padding: 5px 0px 15px;
}
.profile-button-container button {
  box-shadow: 0px 0px 2px #000;
  margin-right: 10px;
}

.power-logout {
  background: linear-gradient(to left, #cb2d3e, #ef473a) !important;
  color: #fff !important;
  box-shadow: 0px 0px 3px #000;
}

.power-logout:hover {
  box-shadow: 0px 0px 5px #000;
}

.task-drag-Box-Pending {
  background-color: #bae6ff !important;
  border: 1px solid #1772a7;
  border-radius: 10px;
  font-size: 16px;
  padding: 1px 10px;
  color: #1772a7 !important;
}

.task-drag-Box-InProcess {
  background-color: #ffddc6 !important;
  border: 1px solid #a64c11;
  border-radius: 10px;
  font-size: 16px;
  padding: 1px 10px;
  color: #a64c11 !important;
}

.task-drag-Box-Testing {
  background-color: rgba(129, 91, 221, 0.4117647059) !important;
  border: 1px solid rgba(40, 11, 107, 0.4117647059);
  border-radius: 10px;
  font-size: 16px;
  padding: 1px 10px;
  color: rgba(40, 11, 107, 0.4117647059) !important;
}

.task-drag-Box-COMPLETED {
  background-color: #e5f5e9 !important;
  border: 1px solid #09ae33;
  border-radius: 10px;
  font-size: 16px;
  padding: 1px 10px;
  color: #09ae33 !important;
}

.task-drag-Box-Backlogs {
  background-color: #ffc5e3 !important;
  border: 1px solid #c9086c;
  border-radius: 10px;
  font-size: 16px;
  padding: 1px 10px;
  color: #c9086c !important;
}

.priority-color-drag-HIGH {
  background-color: #ffc5e3 !important;
  border: 1px solid #c9086c;
  border-radius: 10px;
  font-size: 16px;
  padding: 1px 10px;
  color: #c9086c !important;
}

.priority-color-drag-MEDIUM {
  background-color: #ffffa4 !important;
  border: 1px solid #777703;
  border-radius: 10px;
  font-size: 16px;
  padding: 1px 10px;
  color: #777703 !important;
}

.priority-color-drag-LOW {
  background-color: #9bff9b !important;
  border: 1px solid #0a950a;
  border-radius: 10px;
  font-size: 16px;
  padding: 1px 10px;
  color: #0a950a !important;
}

.task-card-box {
  width: 100%;
  padding: 15px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 1px #000;
}
.task-card-box .task-navigate-drag-arrow {
  display: none;
}
.task-card-box .task-label span {
  font-size: 14px;
}
.task-card-box .card-title {
  font-size: 18px;
  font-weight: 600;
}
.task-card-box .card-footer .task-due-date {
  font-size: 14px;
}

.task-card-box:hover .task-navigate-drag-arrow {
  display: block;
  color: #ed1c24;
}

hr {
  margin: 4px 0px !important;
}

.color-link a {
  color: #fff !important;
  text-decoration: none;
}

.graph-container {
  max-width: 500px;
  margin: auto;
}

.comment-box-divider {
  border-left: 1px solid #000;
}

.comment-section .message-container {
  overflow-y: auto;
  height: 40vh;
}
.comment-section .message-send-container {
  padding-top: 5px;
  margin-top: 10px;
}
.comment-section .message-send-container .input-comment {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.status-color-drag-ACTIVE {
  background-color: #73c1e0 !important;
  border: 1px solid #0078a8;
  border-radius: 10px;
  font-size: 16px;
  padding: 1px 10px;
  color: #0078a8 !important;
}

.status-color-drag-COMPLETED {
  background-color: #83e671 !important;
  border: 1px solid #0a950a;
  border-radius: 10px;
  font-size: 16px;
  padding: 1px 10px;
  color: #0a950a !important;
}

.status-color-drag-HOLD {
  background-color: #c1a76b !important;
  border: 1px solid #956800;
  border-radius: 10px;
  font-size: 16px;
  padding: 1px 10px;
  color: #956800 !important;
}

.user-profile-image-icon {
  max-width: 35px;
  cursor: pointer;
}
.user-profile-image-icon img {
  border-radius: 50%;
}

.scroll-button-slide {
  overflow: auto;
  white-space: nowrap;
}

.scroll-button-slide::-webkit-scrollbar {
  display: none;
}

.scroll-button-slide::-webkit-scrollbar {
  width: 0 !important;
}

.add-buttons-boxes {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.add-buttons-boxes:hover {
  background-color: rgb(237, 28, 36);
  color: #fff;
}

.profile_name {
  font-size: 15px;
}

.task_slider {
  width: 99%;
  margin: auto;
}

.slick-slide {
  padding: 0px 6px;
}

.user-card {
  overflow: hidden;
}
.user-card .image-part {
  margin-bottom: 5px;
}
.user-card .user-work-info {
  margin: 8px 0px;
}
.user-card .user-work-info .count {
  font-weight: 600;
  font-size: 18px;
}
.user-card .user-work-info .title {
  font-size: 15px;
}
.user-card .name {
  font-weight: 500;
}
.user-card .email {
  font-size: 15px;
}
.user-card .email span svg {
  font-size: 13px;
  color: #ed1c24;
}
.user-card .user-button {
  margin: 12px 0px 0px;
}

.card-container {
  background-color: #fff;
  border-radius: 10px;
  padding: 15px 10px;
  margin: 5px 5px 5px 0px;
  overflow: auto;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2117647059);
}

.card-container:hover {
  background-color: #fff;
  box-shadow: 0px 0px 5px #000;
}

.star-view .file-name-cont .file-input {
  padding: 0px 8px 0px 5px;
}
.star-view .file-name-cont .file-input input {
  transform: scale(1.2);
}
.star-view .file-name-cont .file-star {
  padding: 0px 0px 0px 3px;
}
.star-view .file-name-cont .file-star svg {
  font-size: 26px;
}
.star-view .file-name-cont .file-img {
  padding: 0px 6px 0px 3px;
}
.star-view .file-name-cont .file-img svg {
  color: #ed1c24;
}
.star-view .file-name-cont .file-name {
  padding: 0px;
}

.dropzone-scroll {
  overflow-y: auto !important;
}

.dropzone-module .dzu-dropzone {
  overflow: hidden !important;
}

.nav-item-tabs {
  display: flex;
  align-items: center;
}
.nav-item-tabs .nav-item-icon {
  padding: 6px 8px;
}
.nav-item-tabs .nav-item-icon svg {
  font-size: 30px;
}
.files-tab {
  border: none !important;
  cursor: pointer;
  margin: 6px 0px;
}
.files-tab .file {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.file-tab-active {
  background-color: #ed1c24 !important;
  border-radius: 12px !important;
  color: #fff !important;
}

.files-tab:hover {
  background-color: #ed1c24;
  border-radius: 12px;
  color: #fff;
}

.shared-tab-container {
  border-bottom: 2px solid #e6e6e6;
}
.shared-tab-container .shared-tab {
  padding: 8px 16px;
  margin: 0px 1px;
  font-size: 18px;
  cursor: pointer;
}
.shared-tab-container .shared-tab:hover {
  background-color: rgb(249, 156, 156);
}
.shared-tab-container .shared-tab-active {
  font-weight: 600;
  background-color: rgb(249, 156, 156);
  border-bottom: 4px solid #ed1c24 !important;
}

.file-card .file-image-part {
  max-width: 100px;
  margin: auto;
}
.file-card .file-details {
  margin-top: 4px;
}
.file-card .file-details .name span svg {
  font-size: 26px;
}
.file-card .file-details .other {
  color: #939393;
  font-size: 13px;
}

.new-datepicker {
  width: 100%;
}
.new-datepicker .react-date-picker__wrapper {
  padding: 5px 8px;
  border-radius: 5px;
  background-color: #fff;
}

.project-color-INACTIVE {
  background-color: #fb154b;
}

.sidersheet-drawer-300 {
  width: 300px;
}

.sidersheet-drawer-400 {
  width: 400px;
}

.sidersheet-drawer-500 {
  width: 500px;
}

.sidersheet-drawer-600 {
  width: 600px;
}

.sidersheet-drawer-700 {
  width: 700px;
}

@media (max-width: 600px) {
  .home-obj {
    display: none;
  }
  .auth-img-side {
    display: none;
  }
}/*# sourceMappingURL=main.css.map */