$small: 600px;
$medium: 900px;
$large: 1536px;

.sidersheet-drawer-300 {
  width: 300px;
}
.sidersheet-drawer-400 {
  width: 400px;
}
.sidersheet-drawer-500 {
  width: 500px;
}
.sidersheet-drawer-600 {
  width: 600px;
}
.sidersheet-drawer-700 {
  width: 700px;
}

.side-context-section {
}
// @media screen and (max-width: $small) {
//   .side-context-section {
//     width: 500px !important;
//   }
// }
// @media (min-width: $small) and (max-width: $medium) {
//   .side-context-section {
//     max-width: 200px;
//   }
// }

@media (max-width: $small) {
  .home-obj {
    display: none;
  }
  .auth-img-side {
    display: none;
  }
}
